import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer>
    <div>
        <p>Copyright &copy; 2012-2023 重庆乾阳酒类销售股份有限公司版权所有</p>
    </div>
    </footer>
  );
}

export default Footer;