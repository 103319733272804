const words = {
    CompanyIntroduction: `
    重庆乾阳酒类销售股份有限公司，负责泸州老窖国窖1573系列，中高端品牌紫砂大曲系列，泸州老窖黑盖二曲产品在渝的品牌运营与推广服务。
    注册资金1000万，员工70余人，已成立了以批发、团购、酒行、餐饮为销售渠道的综合业务团队。
    在发展过程中，公司也因此深受广大消费者的青睐。公司自成立以来，始终坚持以“品质保证、服务专业、顾客满意”为经营宗旨，以“以人为本，顾客至上”为经营理念，开拓进取，务实创新，
    做好酒类经营。“团结、诚信、敬业、奋斗”的乾阳人，将在专业化与多元化并行的轨道上稳健、持续地前行，将最好的产品与服务带给我们最需要的消费者，乾阳人一直在努力。
    `,
    // You can add more content here as needed
    serious1:'泸州老窖·国窖1573系列重庆总经销商',
    serious2: '泸州老窖·紫砂大曲系列产品重庆分公司<br>泸州老窖·紫砂大曲系列重庆总经销商',
    serious3:'泸州老窖·黑盖二曲酒重庆总经销商',
    
    job1:
    "职位描述：<br>" +
    "1. 区域内酒行终端开发、拜访、客情维护和管理工作，达成销售目标；<br>" +
    "2. 区域内销售活动的执行、检查、报表工作；<br>" +
    "3. 市场信息收集，分析，竞品跟踪。<br>" +
    "<br>"+
    "任职要求：<br>" +
    "1. 高中以上文凭，35岁以下。<br>" +
    "2. 吃苦耐劳，有进取精神，有快消品销售工作经验优先。<br>" +
    "3. 拥有良好的沟通能力、能够独立开拓市场，能承受一定压力；<br>" +
    "4. 责任心强，有较强的个人计划与执行能力、良好的团队意识与合作精神。<br>" +
    "<br>"+
    "经验/学历：无需工作经验/高中以上 <br>" +  
    "年龄：20-35岁"
  
  };
  
  export default words;