import React from 'react';
import '../styles/Empty.css';

function Empty() {
  return (
    <div>
    <section id="dev">
        <div class="devclass">
            <h1>该网页正在维护中</h1>
            <p>联系销售代表以订购产品</p>
        </div>
    </section>
    
    </div>
  );
}

export default Empty;